import React from "react";
import {Box} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/styles';

const HeaderHeight = () => {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('md'));

  return <Box sx={{
   display: "flex",
   flexDirection: "row",
   alignItems: "flex-end",
   justifyContent: "center",
   color: 'text.secondary',
   minHeight: sm ? '92px' : '99px',
   height: '100%',
   padding: "36px 23px"
  }}/>
};

export default HeaderHeight;
